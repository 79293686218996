<template>
	
		
	<div 
		v-if="small && !expanded"
		class="bg-beige border border-beige-dark overflow-hidden flex rounded w-full h-12 items-center">
		
		<div 
			class="flex-none w-12 h-full mr-4 flex items-center justify-center text-2xl"
			:class="campaign.status && campaign.status == 'ongoing' ? 'bg-green text-white' : 'bg-beige-dark'">
			
			<SvgIcon 
				icon="campaigns" 
				class="w-4 h-4"
			/>
		</div>
		
		{{campaign.name}}
	</div>
	<div 
		v-else
		class="bg-white border border-beige-dark overflow-hidden flex rounded-lg w-full">
		
		<div 
			class="flex-none w-24 flex items-center justify-center text-4xl"
			:class="campaign.status && campaign.status == 'ongoing' && campaign.published ? 'bg-green text-white' : 'bg-beige-dark'">
			
			<SvgIcon 
				icon="campaigns" 
				class="w-8 h-8"
			/>
		</div>
		
		<div class="flex-grow p-8">
			<h3 class="text-lg font-bold leading-tight">{{campaign.name}}</h3>
			
			<div class="flex mb-2">
				{{campaign.startAt | moment('ll')}} til {{campaign.endAt | moment('ll')}}
			</div>
			
			<div class="opacity-75 mt-4 leading-tight">
				<span v-if="campaign.type">{{ campaign.type == 'products' ? 'Produkter i ' : 'Oppgaver i ' }}</span>
				<span class="text-gray-darkest">{{ department && department.name ? department.name : 'Ukjent avdeling' }}</span>
			</div>
			
			<!-- <pre>{{ campaign.departmentId }}</pre> -->
			
			<div 
				v-if="expanded"
				class="flex flex-col gap-4">
				
				<!-- <div class="flex flex-col mt-4">
					<div class="md:w-1/4 text-gray-light">
						Avdeling
					</div>
					<div class="w-3/4">
						{{ department && department.name ? department.name : 'Avdeling ikke funnet' }}
					</div>
				</div> -->
				
				<div class="flex flex-col">
					<div class="md:w-1/4 text-gray-light">
						Beskrivelse
					</div>
					<div class="w-3/4">
						<p v-html="campaign.description"/>
					</div>
				</div>
				
				<!-- <div class="flex flex-col">
					<div class="md:w-1/4 text-gray-light">
						Mål
					</div>
					<div class="w-3/4">
						{{campaign.metric == 'total' ? 'Totalt salg på avdelingsprodukter' : 'Individuelt på avdelingsprodukter'}}
					</div>
				</div> -->
				
				<!-- <div class="">
					<pre v-html="campaign"/>
				</div> -->
			</div>
			
			<!-- <pre>{{campaign.departmentId}}</pre> -->
		</div>
	</div>
</template>

<script>
export default {
	props: {
		campaign: {
			type: Object,
			required: true,
		},
		
		expanded: {
			type: Boolean,
			required: false,
			default: false,
		},
		
		small: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	
	computed:{
		departments(){
			return this.$store.getters.getAllDepartments;
		},
		
		department(){
			return this.departments.find( d => d.departmentId == this.campaign.departmentId );
		},
	},
	
	data() {
		return {
			loading: null,
		}
	},
}
</script>

<style lang="scss">
</style>